import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import i18n from "eth-common/i18n/i18n";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
  Table,
} from "react-bootstrap";
import { StartSigning } from "./StartSigning";
import { FetchWithAuth } from "digimaker-ui/util";
import { SigningDetail } from "eth-common/signature/SigningDetail";

export const SignaturePanel = (props: { report: any; lang?: string }) => {
  const { report } = props;

  const [loading, setLoading] = useState(true);
  const [signatureData, setSignatureData] = useState<any>(null);

  const fetchSignatureData = () => {
    FetchWithAuth(
      "eth/signing/get-signature-record/" + report.location.id
    ).then((data) => {
      setSignatureData(data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchSignatureData();
  }, []);

  if (loading) {
    return <span className="loading"></span>;
  }

  return (
    <div>
      <span
        className={
          "workflow-status workflow-lang-" +
          props.lang +
          " signing-status-" +
          (signatureData ? signatureData.status : 0)
        }
      >
        {!signatureData || signatureData.status === 0
          ? i18n.t("Not started")
          : ""}
        {signatureData && signatureData.status === 2
          ? i18n.t("In signing")
          : ""}
        {signatureData && signatureData.status === 1
          ? i18n.t("Signing complete")
          : ""}
      </span>
      &nbsp;&nbsp;
      {signatureData &&
        (signatureData.status === 2 || signatureData.status === 1) && (
          <SigningDetail report={report} />
        )}
      {(!signatureData || signatureData.status === 0) && (
        <StartSigning
          report={props.report}
          language={props.lang}
          onSuccess={() => fetchSignatureData()}
        />
      )}
    </div>
  );
};
