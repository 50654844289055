import { FetchWithAuth } from "digimaker-ui/util";

let settingData = null;

export const Util = {
  formSessionID: "" as any,
  getFormSessionID: () => {
    if (!Util.formSessionID) {
      Util.formSessionID = Math.floor(Date.now() / 1000);
    }
    return Util.formSessionID;
  },
  validateEmail: (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  },
  getContentUrl: (content) => {
    let path = content.location.identifier_path;
    path = path.substr((process.env.REACT_APP_CONTENT_PREFIX + "").length);
    return path;
  },
  fetchSettings: async () => {
    if (settingData) {
      return settingData;
    } else {
      let data = await FetchWithAuth(
        process.env.REACT_APP_REMOTE_URL + "/eth/setting/get"
      );
      settingData = data.data;
      return data;
    }
  },
  getCurrentForm: (user) => {
    let result = "";
    if (user && user.member) {
      let reportType = user.member.report_type;
      if (reportType && reportType.value === "business") {
        result = "report";
      } else if (reportType && reportType.value === "public_sector") {
        result = "report_public_sector";
      }
    }
    return result;
  },
};
