import * as React from "react";
import { RouteProps } from "react-router";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import WorkflowLog from "./WorkflowLog";
import "./workflow.css";
import Level from "eth-common/Level";
import ImportReport from "./ImportReport";
import MessageList from "../user/MessageList";
import i18n from "eth-common/i18n/i18n";
import { EthContext } from "eth-common/EthContext";
import fetch from "isomorphic-fetch";
import { FetchWithAuth } from "digimaker-ui/util";
import ViewPDF from "eth-common/ViewPDF";
import { Util } from "../util/Util";
import { SignaturePanel } from "./signature/SignaturePanel";

export default class MyReport extends React.Component<
  {},
  {
    loading: boolean;
    warning: any;
    content: any;
    activitylist: any;
    draftTime: any;
    reportPdf: any;
    draftPdf: any;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      warning: "",
      content: "",
      activitylist: "",
      draftTime: "",
      reportPdf: null,
      draftPdf: null,
    };
  }

  async fetchContent() {
    let reportData = await FetchWithAuth(
      process.env.REACT_APP_REMOTE_URL + "/eth/report/current"
    );
    if (reportData.data) {
      this.setState({ content: reportData.data });
      this.fetchComment(reportData.data.id);
    } else {
      this.setState({ content: false });
    }
  }

  fetchDraft() {
    this.setState({ loading: true });
    FetchWithAuth(
      process.env.REACT_APP_REMOTE_URL +
        "/eth/report/draft?session_id=" +
        Util.getFormSessionID()
    )
      .then((data) => {
        if (!data.error) {
          this.setState({
            warning: "",
            draftTime: data.data.created,
            loading: false,
          });
        } else {
          this.setState({ warning: data.data.message, loading: false });
        }
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  fetchComment(id) {
    FetchWithAuth(
      process.env.REACT_APP_REMOTE_URL + "/workflow/activitylist/" + id
    ).then((data) => {
      this.setState({ activitylist: data.data });
    });
  }

  //when init
  async componentDidMount() {
    this.fetchContent();
    this.fetchDraft();
  }

  draftPdf(e) {
    e.preventDefault();
    this.setState({ draftPdf: 0 });
    FetchWithAuth(
      process.env.REACT_APP_REMOTE_URL +
        "/eth/report/draftpdf?language=" +
        this.context.language
    ).then((data) => {
      if (data.error === false) {
        this.setState({ draftPdf: data.data });
      } else {
        this.setState({ draftPdf: "" });
      }
    });
  }

  viewPdf(e, id) {
    e.preventDefault();
    this.setState({ draftPdf: 0 });
    FetchWithAuth(
      process.env.REACT_APP_REMOTE_URL +
        "/eth/pdf/" +
        id +
        "?language=" +
        this.context.language
    ).then((data) => {
      if (data.error === false) {
        this.setState({
          reportPdf: process.env.REACT_APP_REMOTE_URL + data.data,
        });
      } else {
        this.setState({ reportPdf: "" });
      }
    });
  }

  render() {
    let content = this.state.content;
    if (content === "") {
      return <div className="loading"></div>;
    }
    let language = this.context.language;
    let user = this.context.user;

    if (!user) {
      return <span>...</span>;
    }
    return (
      <div className="content-wrapper">
        <div className="right">
          <Link to="/myreport/history">{i18n.t("History")}</Link>&nbsp;|&nbsp;
          <Link to="/myreport/benchmark">Benchmark</Link>&nbsp;|&nbsp;
          <Link to="/myreport/settings">{i18n.t("Report images")}</Link>
        </div>

        <h2>
          {i18n.t("Reporting in")} {this.context.currentYear}
        </h2>
        <div className="myreport-status">
          {this.state.loading && <span className="loading"></span>}
          {this.state.warning && (
            <>
              <br />
              <br />

              <div className="alert alert-warning">
                {this.state.warning} <br /> <br />
                <button
                  className="btn btn-secondary btn-sm"
                  onClick={() => this.fetchDraft()}
                >
                  Recheck
                </button>
              </div>
            </>
          )}

          {this.state.warning == "" && (
            <>
              {/* Having draft already */}
              {this.state.draftTime && (
                <div>
                  <div className="alert alert-success">
                    {i18n.t("You have draft from")}{" "}
                    <Moment unix format="DD.MM.YYYY HH:mm">
                      {this.state.draftTime}
                    </Moment>{" "}
                    &nbsp;
                    <a href="#" onClick={(e) => this.draftPdf(e)}>
                      <i className="fas fa-file-pdf"></i>{" "}
                      {i18n.t("PDF from Draft")}
                    </a>{" "}
                    &nbsp;
                    {this.state.draftPdf !== null && (
                      <span>
                        {this.state.draftPdf && (
                          <a href={"/" + this.state.draftPdf} target="_blank">
                            {i18n.t("Click to view")}
                          </a>
                        )}
                        {this.state.draftPdf === 0 && (
                          <span className="loading"></span>
                        )}
                        {this.state.draftPdf === "" && (
                          <span>{i18n.t("Error when generating")}</span>
                        )}
                      </span>
                    )}
                  </div>
                  {content === false && (
                    <div>
                      <Link to="/report/0" className="btn btn-primary btn-sm">
                        {i18n.t("Continue report")}
                      </Link>
                    </div>
                  )}
                </div>
              )}
              {content && content.status == 0 && (
                <div>
                  <Link
                    to={"/report/" + content.location.id}
                    className="btn btn-primary btn-sm"
                  >
                    {i18n.t("Continue report")}
                  </Link>
                </div>
              )}

              {/* Start from new */}
              {content === false && (
                <div>
                  {!this.state.draftTime && (
                    <Link to="/report/0" className="btn btn-primary btn-sm">
                      {i18n.t("Start report") + " " + this.context.currentYear}
                    </Link>
                  )}
                  <ImportReport />
                </div>
              )}
            </>
          )}
        </div>

        {/* having sent report */}
        {content && (
          <div>
            <h2>{i18n.t("My current report")}</h2>
            <div className="myreport">
              <table>
                <tr>
                  <td>{i18n.t("Report ")}: </td>
                  <td>
                    {content.metadata.name} &nbsp;
                    <Link to={"/myreport/view/" + content.location.id}>
                      <i className="fas fa-eye"></i> {i18n.t("View")}
                    </Link>{" "}
                    &nbsp;
                    <ViewPDF
                      id={content.location.id}
                      language={this.context.language}
                    />
                    &nbsp;
                  </td>
                </tr>
                <tr>
                  <td>{i18n.t("Created")}: </td>
                  <td>
                    <Moment format="DD.MM.YYYY HH:mm">
                      {content.metadata.published}
                    </Moment>
                  </td>
                </tr>
                <tr>
                  <td>{i18n.t("Updated")}: </td>
                  <td>
                    <Moment format="DD.MM.YYYY HH:mm">
                      {content.metadata.modified}
                    </Moment>
                  </td>
                </tr>
                <tr>
                  <td>{i18n.t("Status")}: </td>
                  <td>
                    <span
                      className={
                        "workflow-status status-" +
                        content.status +
                        " workflow-lang-" +
                        this.context.language
                      }
                    ></span>
                  </td>
                </tr>
                {(content.status === 3 || content.status === 4) && (
                  <tr>
                    <td>{i18n.t("Signing status")}: </td>
                    <td>
                      <SignaturePanel
                        report={content}
                        lang={this.context.language}
                      />
                    </td>
                  </tr>
                )}
              </table>
            </div>
            <Tabs className="block myreport-feedback">
              <TabList>
                <Tab>{i18n.t("Messages")}</Tab>
                <Tab>{i18n.t("Level evaluation")}</Tab>
                <Tab>{i18n.t("Log")}</Tab>
              </TabList>
              <TabPanel>
                <MessageList count={2} />
              </TabPanel>
              <TabPanel>
                <div className="block">
                  <Level content={content} />
                </div>
              </TabPanel>
              <TabPanel>
                <WorkflowLog content={content} />
              </TabPanel>
            </Tabs>
          </div>
        )}
      </div>
    );
  }
}

MyReport.contextType = EthContext;
