import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import i18n from "eth-common/i18n/i18n";
import ViewPDF from "eth-common/ViewPDF";
import {
  Alert,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
  Overlay,
  OverlayTrigger,
  Table,
} from "react-bootstrap";
import { FetchWithAuth } from "digimaker-ui/util";
import { Util } from "../../util/Util";

export const StartSigning = (props: {
  report: any;
  language?: string;
  onSuccess: () => void;
}) => {
  const { report } = props;

  const startButton = useRef(null);

  const [shown, setShown] = useState(false);
  const [showConfim, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");

  const emptyRecord = { name: "", email: "", title: "" };

  const [signaturePage, setSignaturePage] = useState(5);

  const [receivers, setReceivers] = useState<
    Array<{ name: string; email: string; title: string }>
  >([emptyRecord]);

  const add = () => {
    setReceivers([...receivers, emptyRecord]);
  };

  const change = (index: number, t: string, v: string) => {
    const newReceivers = [...receivers];
    const newRow = { ...receivers[index] };
    if (t === "name") {
      newRow.name = v;
    } else if (t === "email") {
      newRow.email = v;
    } else if (t === "title") {
      newRow.title = v;
    }

    newReceivers[index] = newRow;
    setReceivers(newReceivers);
  };

  const deleteRow = (index: number) => {
    const newReceivers = [...receivers];
    newReceivers.splice(index, 1);
    setReceivers(newReceivers);
  };

  const start = () => {
    let validationError = "";
    for (const item of receivers) {
      if (!item.name) {
        validationError += i18n.t("Name can't be empty.");
      }
      if (!Util.validateEmail(item.email)) {
        validationError += i18n.t("Invalid email:") + item.email + ". ";
      }
    }
    if (validationError) {
      setError(validationError);
    } else {
      setError("");
      setShowConfirm(true);
    }
  };

  const confirmStart = () => {
    setLoading(true);
    FetchWithAuth("eth/signing/start/" + report.location.id, {
      method: "POST",
      body: JSON.stringify({
        receivers: receivers,
        signature_page: signaturePage,
      }),
    }).then((data) => {
      setLoading(false);
      if (data.data === true) {
        window.alert(i18n.t("Successfully started"));
        setShown(false);
        props.onSuccess();
      } else {
        window.alert(data.data.message);
      }
    });
  };

  return (
    <>
      <Button size="sm" onClick={() => setShown(true)}>
        {" "}
        {i18n.t("Start signing")}
      </Button>
      <Modal show={shown} centered size="lg">
        <ModalTitle style={{ padding: 10 }}>
          {i18n.t("Start signing")}
        </ModalTitle>
        <ModalBody>
          <Table className="table">
            <thead>
              <th></th>
              <th>{i18n.t("Name")}</th>
              <th>{i18n.t("Title")}</th>
              <th>{i18n.t("Email")}</th>
              <th></th>
            </thead>
            {receivers.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <input
                    className="form-control"
                    value={item.name}
                    placeholder={i18n.t("Name")}
                    onChange={(e) => change(index, "name", e.target.value)}
                  />
                </td>
                <td>
                  <input
                    className="form-control"
                    value={item.title}
                    placeholder={"Title under signature"}
                    onChange={(e) => change(index, "title", e.target.value)}
                  />
                </td>
                <td>
                  <Form.Control
                    type="email"
                    value={item.email}
                    placeholder={i18n.t("Input email")}
                    onChange={(e) => change(index, "email", e.target.value)}
                  />
                </td>
                <td>
                  <Button variant="light" onClick={() => deleteRow(index)}>
                    <i className="fa fa-trash"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </Table>
          <Button variant="secondary" size="sm" onClick={add}>
            {i18n.t("Add")}
          </Button>
          <br />
          <br />
          <div>
            <label>{i18n.t("Signature page")}:</label>
            <span>
              <Button
                size="sm"
                variant={signaturePage === 5 ? "primary" : "light"}
                onClick={() => setSignaturePage(5)}
              >
                5
              </Button>
              <Button
                size="sm"
                variant={signaturePage === 6 ? "primary" : "light"}
                onClick={() => setSignaturePage(6)}
              >
                6
              </Button>
            </span>
          </div>
          <div>
            <span style={{ fontSize: "90%", marginLeft: 5 }}>
              {i18n.t(
                "If the preface is over one page, select page 6 for board signatures."
              )}
            </span>
            <span>
              &nbsp;
              <ViewPDF id={report.location.id} language={props.language} />
            </span>
          </div>
          <br />
          {showConfim && (
            <Alert variant="info">
              <b>{i18n.t("Confirm to start?")}</b>
              <br />
              {i18n.t(
                "Note if emails are wrong the signing wil not go through."
              )}
            </Alert>
          )}
          {error && <Alert variant="danger">{error}</Alert>}
        </ModalBody>
        <ModalFooter>
          {loading && <span className="loading"></span>}
          {!showConfim && (
            <Button
              disabled={loading || showConfim || receivers.length === 0}
              onClick={start}
            >
              {i18n.t("Start")}
            </Button>
          )}
          {showConfim && (
            <Button onClick={confirmStart} disabled={loading}>
              {" "}
              {i18n.t("Confirm to start")}
            </Button>
          )}
          <Button
            disabled={loading}
            variant="light"
            onClick={() => setShown(false)}
          >
            {i18n.t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
